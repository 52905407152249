<template>
  <authenticator>
    <template v-slot:header>
      <div style="padding: var(--amplify-space-large); text-align: center" class="text-h5">
하우학교 행정 시스템
      </div>
    </template>
    <router-view />
  </authenticator>
</template>

<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { API, graphqlOperation } from 'aws-amplify';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import * as queries from './graphql/queries';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui';
I18n.setLanguage('kr');

I18n.putVocabularies({
  kr: {
    'Sign In': '로그인',
    'Sign in': '로그인',
    'Signing in': '로그인 중',
    'Sign In with Google': '하우학교(howcs.kr) 계정 로그인',
    'Username': '학번 (s0000)',
    'Password': '비밀번호',
    'Forgot your password? ': "비밀번호 분실 (초기화)",
    'Reset your password': "비밀번호 초기화",
    'Enter your username': "학번(s0000) 입력",
    'Send Code': "인증 코드를 이메일로 전송",
    'Back to Sign In': "로그인 화면으로 이동",
    'Account recovery requires verified contact information': '이메일 변경 시, 인증코드 입력이 필요합니다.',
    'Code': '인증번호',
    'Submit': '제출',
    'Email': '이메일',
    'Verify': '검증',
    'Skip': '건너뛰기',
    'Change Password': '비밀번호 변경',
    'Confirm Password': '비밀번호 확인',
    'Change Password': '비밀번호 변경',
    'Reset your Password': '비밀번호 변경',
  },
});

Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

export default {
  name: 'App',
  components: {
    Authenticator
  },
}
</script>

<style>
.amplify-tabs {
  display: none;
}
</style>
