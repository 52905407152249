/*
export function someMutation (state) {
}
*/
export function setAuthUser(state, user) {
  state.user = user;
}

export function setAuthUserGroup(state, group) {
  state.group = group;
}
