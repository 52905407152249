
const routes = [
  {
    path: '/print',
    name: 'print',
    component: () => import('components/Report'),
    props: true,
  },
  {
    path: '/printFruit',
    name: 'printFruit',
    component: () => import('components/Fruit'),
    props: true,
  },
  {
    path: '/post',
    component: () => import('layouts/UserTopMenu'),
    props: {leftDrawerOpen: false},
    children: [
      { path: ':category', name: 'post', component: () => import('pages/Post.vue'), props: true }
    ]
  },
  {
    path: '/home',
    component: () => import('layouts/Home'),
    props: {leftDrawerOpen: false},
    children: [
      { path: '', name: 'home', component: () => import('pages/home/Home.vue') }
    ]
  },
  {
    path: '/about',
    component: () => import('layouts/Home'),
    props: {leftDrawerOpen: false},
    children: [
      { path: '', name: 'about', component: () => import('pages/home/about/About.vue') },
      { path: 'goal', name: 'about_goal', component: () => import('pages/home/about/Goal.vue') },
      { path: 'worth', name: 'about_worth', component: () => import('pages/home/about/Worth.vue') }
    ]
  },
  {
    path: '/entrance',
    component: () => import('layouts/Home'),
    props: {leftDrawerOpen: false},
    children: [
      { path: '', name: 'entrance', component: () => import('pages/home/entrance/Entrance.vue') }
    ]
  },
  {
    path: '/community',
    component: () => import('layouts/Home'),
    props: {leftDrawerOpen: false},
    children: [
      { path: '', name: 'community', component: () => import('pages/home/community/Community.vue') }
    ]
  },
  {
    path: '/edu',
    component: () => import('layouts/Home'),
    props: {leftDrawerOpen: false},
    children: [
      { path: '', name: 'edu', component: () => import('pages/home/edu/Edu.vue') },
      { path: 'edu1', name: 'edu1', component: () => import('pages/home/edu/Edu1.vue') },
      { path: 'edu2', name: 'edu2', component: () => import('pages/home/edu/Edu2.vue') },
      { path: 'edu3', name: 'edu3', component: () => import('pages/home/edu/Edu3.vue') },
      { path: 'edu4', name: 'edu4', component: () => import('pages/home/edu/Edu4.vue') },
      { path: 'edu5', name: 'edu5', component: () => import('pages/home/edu/Edu5.vue') },
      { path: 'edu6', name: 'edu6', component: () => import('pages/home/edu/Edu6.vue') }
    ]
  },
  {
    path: '/story',
    component: () => import('layouts/Home'),
    props: {leftDrawerOpen: false},
    children: [
      { path: 'story', name: 'story', component: () => import('pages/home/story/Story.vue') },
      { path: 'notice', name: 'notice', component: () => import('pages/home/story/Notice.vue') },
      { path: 'photo', name: 'photo', component: () => import('pages/home/story/Photo.vue') },
      { path: 'story_view/:id', name: 'story_view', component: () => import('pages/home/story/StoryView.vue'), props: true },
      { path: 'photo_view/:id', name: 'photo_view', component: () => import('pages/home/story/PhotoView.vue'), props: true }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/UserTopMenu.vue'),
    children: [
      { path: '', name: 'home3', component: () => import('pages/Greeting.vue') },
      { path: 'profile', component: () => import('pages/MyProfile.vue') },
    ]
  },
  {
    path: '/user',
    component: () => import('layouts/UserTopMenu.vue'),
    children: [
      { path: 'list/:role/:group', component: () => import('pages/UserList.vue'), props: true },
      { path: 'profile', name: 'user_profile', component: () => import('pages/UserProfile.vue') },
      { path: 'report/public/:role/:reportYear', name: 'user_report', component: () => import('pages/UserReport.vue'), props: true },
      { path: 'report/fruit/:role', name: 'user_fruit', component: () => import('pages/UserFruit.vue'), props:true },
      { path: 'report/fruit/:role/:routeSemester', name: 'user_fruit', component: () => import('pages/UserFruit.vue'), props:true },
    ]
  },
  {
    path: '/class',
    component: () => import('layouts/UserTopMenu.vue'),
    children: [
      { path: ':role/:classCategory', component: () => import('pages/ClassList.vue'), props: true },
      { path: ':role/:classCategory/:detail', component: () => import('pages/ClassList.vue'), props: true },
      { path: ':role/:classCategory/:detail/:routeSemester', component: () => import('pages/ClassList.vue'), props: true },
    ]
  },
  {
    path: '/enrollment',
    component: () => import('layouts/UserTopMenu.vue'),
    children: [
      { path: ':role/:classCategory', component: () => import('pages/EnrollmentList.vue'), props: true },
    ]
  },
  {
    path: '/record',
    component: () => import('layouts/UserTopMenu.vue'),
    children: [
      { path: ':role/:classCategory/:recordCategory', component: () => import('pages/ClassRecordList.vue'), props: true },
      { path: ':role/:classCategory/:recordCategory/:routeSemester', component: () => import('pages/ClassRecordList.vue'), props: true },
    ]
  },
  {
    path: '/teacher/',
    component: () => import('layouts/UserTopMenu.vue'),
    children: [
      { path: 'record/:routeClassCategory/:recordCategory', name: "teacherRecord", component: () => import('pages/ClassRecordList.vue'), props: true },
    ]
  },
  {
    path: '/settings/',
    component: () => import('layouts/UserTopMenu.vue'),
    children: [
      { path: ':category', component: () => import('pages/Settings.vue'), props: true },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
